<template>
  <v-container fluid>
    <base-material-card
      icon="fa fa-truck"
      title="Reporte de No Visitas"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_desde" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_desde" label="Fecha desde" readonly v-on="on"
                            :error-messages="errors.fecha_desde"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_desde" @input="menu_fecha_desde = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_hasta" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_hasta" label="Fecha hasta" readonly v-on="on"
                            :error-messages="errors.fecha_hasta"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_hasta" @input="menu_fecha_hasta = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/rutas/all"
            text="nombre"
            label="Ruta"
            @change="
							(value) => {
								form.ruta_id = value;
								load();
							}
						"
            :valor="form.ruta_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/vendedores/all"
            text="razon_social"
            label="Vendedor"
            @change="
							(value) => {
								form.vendedor_id = value;
								load();
							}
						"
            :valor="form.vendedor_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/clientes/all"
            text="razon_social"
            label="Cliente"
            @change="
							(value) => {
								form.cliente_id = value;
								load();
							}
						"
            :valor="form.cliente_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/motivos_no_visitas/all"
            text="nombre"
            label="Motivo no visita"
            @change="
							(value) => {
								form.motivos_no_visita_id = value;
								load();
							}
						"
            :valor="form.motivos_no_visita_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="downloadPdf">Descargar PDF</v-btn>
          <v-btn color="green" @click="downloadExcel">Descargar Excel</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="no_visitas"
            :loading="loading"
            hide-default-footer
            disable-form
          >
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination v-model="form.page" class="my-4"
                        :length="form.last_page"
                        @input="load" :total-visible="10"
                        color="primary"></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>

import moment from 'moment';
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";


export default {
  name:'ReporteNoVisitas',
  components: {
    CustomSelect,
  },
  data: () => ({
    errors: {},
    headers: [
      {text: "Fecha", value: "fecha", sortable: false},
      {text: "Cliente", value: "sucursal.cliente.razon_social", sortable: false},
      {text: "Sucursal", value: "sucursal.nombre", sortable: false},
      {text: "Vendedor", value: "vendedor.razon_social", sortable: false},
      {text: "Ruta", value: "ruta.nombre", sortable: false},
      {text: "Tipo Motivo", value: "motivo_no_visita.nombre", sortable: false},
      {text: "Motivo", value: "descripcion_no_visita", sortable: false},
    ],
    form: {
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: moment().format('YYYY-MM-DD'),
      ruta_id: "",
      vendedor_id: "",
      cliente_id:'',
      sucursal_id:'',
      motivos_no_visita_id:'',
      last_page: 1,
      page: 1,
      model:true
    },
    menu_fecha_desde: false,
    menu_fecha_hasta: false,
    loading: false,
    no_visitas: [],
  }),
  mounted() {
    this.load();
  },
  methods: {
    reset() {
      this.form.page = 1;
      this.load();
    },
    load() {
      this.loading = true;
      this.rutas = [];
      this.$http
        .get("/reportes/rutas/no_visitas", {
          params: this.form,
        })
        .then((response) => {
          this.no_visitas = response.data.data;
          this.total = response.data.cantidad_sum;
          this.totales = response.data.totales;
          this.form.last_page = response.data.last_page;
          this.form.page = response.data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadPdf() {
      this.$http({
        url: `/reportes/rutas/no_visitas/pdf`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteNoVisitas.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    downloadExcel(item) {
      this.$http({
        url: `/reportes/rutas/no_visitas/excel`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteNoVisitas.xlsx`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    retryDownloadPdf(item) {
      this.$http
        .patch(`/facturas/${item.id}/retry-download`)
        .then((response) => {
          this.$store.commit("SET_SNACKBAR", {
            text: "Solicitud de generación de PDF exitosa; intente descarga más tarde",
            color: "success",
          });
        });
    },
  },
};
</script>
